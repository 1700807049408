<template>
    <div>
        <el-dialog
            :close-on-click-modal="false"
            title="操作记录"
            :visible.sync="show"
            width="70%"
            :before-close="close"
            append-to-body
        >
            <el-tabs v-model="tabActive">
                <el-tab-pane label="导入记录" name="1"> </el-tab-pane>
                <el-tab-pane label="导出记录" name="2">
                    <!-- <jf-table
                        :columns="operationLogColumn"
                        :filter-btn="false"
                        :query-params="operationLogQueryParams"
                        :columns-btn="false"
                        :sort-btn="false"
                        :total="total"
                        :data="operationLog"
                        v-if="tabActive * 1 === 2"
                        :checkbox="false"
                        :operate="false"
                    ></jf-table> -->
                </el-tab-pane>
                <el-tab-pane label="修改记录" name="3">
                    <!-- <jf-table
                        :columns="operationLogColumn"
                        :filter-btn="false"
                        :query-params="operationLogQueryParams"
                        :columns-btn="false"
                        :sort-btn="false"
                        :total="total"
                        :data="operationLog"
                        v-if="tabActive * 1 === 3"
                        :checkbox="false"
                        :operate="false"
                    ></jf-table> -->
                </el-tab-pane>
                <el-tab-pane label="删除记录" name="4">
                    <!-- <jf-table
                        :columns="operationLogColumn"
                        :filter-btn="false"
                        :query-params="operationLogQueryParams"
                        :columns-btn="false"
                        :sort-btn="false"
                        :data="operationLog"
                        :total="total"
                        v-if="tabActive * 1 === 4"
                        :checkbox="false"
                        :operate="false"
                    ></jf-table> -->
                </el-tab-pane>
                <el-tab-pane label="打印记录" name="5">
                    <!-- <jf-table
                        :columns="operationLogColumn"
                        :filter-btn="false"
                        :query-params="operationLogQueryParams"
                        :columns-btn="false"
                        :sort-btn="false"
                        :total="total"
                        :data="operationLog"
                        v-if="tabActive * 1 === 5"
                        :checkbox="false"
                        :operate="false"
                    ></jf-table> -->
                </el-tab-pane>
            </el-tabs>
            <jf-table
                :columns="operationLogColumn"
                :query-params="operationLogQueryParams"
                @page-change="handleSizeChange"
                :filter-btn="false"
                :columns-btn="false"
                :total="total"
                :sort-btn="false"
                :data="operationLog"
                :checkbox="false"
                :operate="false"
            >
                <template slot="status" slot-scope="{ row }">
                    <el-tag :type="row.status * 1 === 1 ? 'success' : ''">{{
                        row.status * 1 === 1 ? "已完成" : "正在处理"
                    }}</el-tag>
                </template>
            </jf-table>
            <div
                id=""
                class="justify-content-end d-flex"
                style="margin: 20px 0"
            >
                <el-button @click="close">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { FormxOprloglist } from "@/api/form/data";
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        defaultMid: {
            type: [String, Number],
            default: "",
        },
    },
    data() {
        return {
            operationLog: [],
            tabActive: "1",
            operationLogQueryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                name: undefined,
                no: undefined,
            },
            mid: "",
            total: 0,
        };
    },
    computed: {
        operationLogColumn: function () {
            if (this.tabActive * 1 === 5) {
                return [
                    {
                        field: "name",
                        title: "名称",
                    },
                    {
                        field: "username",
                        title: "时间",
                    },
                    {
                        field: "time",
                        title: "打印人",
                    },
                    {
                        field: "num",
                        title: "打印条数",
                    },
                    {
                        field: "status",
                        title: "打印状态",
                    },
                    {
                        field: "",
                        title: "操作",
                        slots: {
                            default: "defaultopr",
                        },
                    },
                ];
            } else if (this.tabActive * 1 === 4) {
                return [
                    {
                        field: "username",
                        title: "用户",
                    },
                    {
                        field: "pkvalue",
                        title: "主键(条件) ",
                    },

                    {
                        field: "status",
                        title: "状态",
                        slots: {
                            default: "status",
                        },
                    },

                    {
                        field: "dcount",
                        title: "记录数",
                    },
                    {
                        field: "oprtime",
                        title: "操作时间",
                    },
                ];
            } else if (this.tabActive * 1 === 3) {
                return [
                    {
                        field: "username",
                        title: "用户",
                    },
                    {
                        field: "pkvalue",
                        title: "主键",
                    },
                    {
                        field: "fieldname",
                        title: "字段名",
                    },
                    {
                        field: "fieldtitle",
                        title: "字段标题",
                    },
                    {
                        field: "originvalue",
                        title: "原始值",
                    },
                    {
                        field: "updatedvalue",
                        title: "更新值",
                    },
                    {
                        field: "oprtime",
                        title: "操作时间",
                    },
                ];
            }
            return [
                {
                    field: "filename",
                    title: "文件名称",
                },
                {
                    field: "username",
                    title: "用户名",
                },
                {
                    field: "oprtime",
                    title: "操作时间",
                },
                {
                    field: "status",
                    title: "状态",
                    slots: {
                        default: "status",
                    },
                },
                {
                    field: "ttc",
                    title: "总记录",
                },
                {
                    field: "successcount",
                    title: "成功记录",
                },
                // {
                //     field: "",
                //     title: "操作",
                //     slots: {
                //         default: "defaultopr",
                //     },
                // },
            ];
        },
    },
    watch: {
        show: {
            handler(val) {
                if (val) {
                    this.mid = this.defaultMid || this.$route.params.mid;
                    this.getList(this.mid);
                }
            },
            deep: true,
            immediate: true,
        },
        tabActive(val) {
            // if (val * 1 > 3) {
            //     this.operationLog = [];
            //     this.total = 0;
            //     return;
            // }
            this.operationLogQueryParams.pageNum = 1;
            this.getList(this.mid);
        },
    },
    // created() {
    //     if (val && this.$route.params.mid) {
    //         this.mid = this.$route.params.mid;
    //         this.getList();
    //     }
    // },
    methods: {
        handleSizeChange({ currentPage, pageSize }) {
            this.operationLogQueryParams.pageNum = currentPage;
            if (this.operationLogQueryParams.pageSize == pageSize) {
                this.getList(this.mid);
            }
            //  else {
            //     this.operationLogQueryParams.pageSize = pageSize;
            //     this.handleQuery();
            // }
        },
        close() {
            this.$emit("update:show", false);
        },
        getList(mid) {
            try {
                FormxOprloglist(
                    mid,
                    this.operationLogQueryParams,
                    this.tabActive
                ).then((res) => {
                    if (res.code === 0) {
                        this.operationLog = res.data.list;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } catch (error) {
                console.error("Error during data processing:", error);
            } finally {
            }
        },
    },
};
</script>

<style>
</style>